import { createAction } from '@/utils/api'

/**
* 全站常用
*/
export const loadOverall = createAction<
 // Response
 {
   result: {
    meeting_url: string
   }
 }
>('GET', '/api/:locale/overall')